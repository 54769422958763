var render = function () {
  var _vm$schema, _vm$schema2, _vm$schema3, _vm$schema4, _vm$schema5, _vm$schema6, _vm$schema7, _vm$schema8, _vm$schema9, _vm$schema10, _vm$schema10$products, _vm$schema11, _vm$schema12, _vm$schema13, _vm$schema14, _vm$schema15, _vm$schema15$referral;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Referral',
        href: '/admin/referrals',
        isCurrent: false
      }, {
        label: 'Detail Skema Referral',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '20px'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center",
      "margin-bottom": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "28px",
      "font-weight": "700",
      "color": "#111"
    }
  }, [_vm._v(" Skema AjakSehat ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px",
      "gap": "8px"
    },
    on: {
      "click": function click() {
        _vm.$router.push({
          name: 'admin.referrals.schema.edit',
          params: {
            id: _vm.$route.params.id
          }
        });
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-edit-2.svg'),
      "height": "20",
      "width": "20",
      "fill": "#FFF"
    }
  }), _vm._v(" Edit ")], 1)], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "width": "100%",
      "padding": "10px 16px",
      "border-bottom": "1px solid #888",
      "font-size": "20px",
      "color": "primary.400",
      "font-weight": "700",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" Banner Skema ")]), _c('c-box', {
    attrs: {
      "padding-inline": "16px"
    }
  }, [(_vm$schema = _vm.schema) !== null && _vm$schema !== void 0 && _vm$schema.bannerUrl ? _c('c-image', {
    attrs: {
      "src": (_vm$schema2 = _vm.schema) === null || _vm$schema2 === void 0 ? void 0 : _vm$schema2.bannerUrl,
      "width": "300px",
      "height": "auto",
      "padding-left": "",
      "margin-bottom": "16px",
      "border-radius": "8px"
    }
  }) : _vm._e(), _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Status Skema ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm.getStatus((_vm$schema3 = _vm.schema) === null || _vm$schema3 === void 0 ? void 0 : _vm$schema3.status)) + " ")])], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "width": "100%",
      "padding": "10px 16px",
      "border-bottom": "1px solid #888",
      "font-size": "20px",
      "color": "primary.400",
      "font-weight": "700",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" Periode Skema ")]), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(2, 1fr)'],
      "gap": ['20px'],
      "mx": "auto",
      "padding-inline": "16px"
    }
  }, [_c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Mulai Periode Kode ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(((_vm$schema4 = _vm.schema) === null || _vm$schema4 === void 0 ? void 0 : _vm$schema4.startAtCase) || '-') + " ")])], 1), _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Akhir Periode Kode ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(((_vm$schema5 = _vm.schema) === null || _vm$schema5 === void 0 ? void 0 : _vm$schema5.endAtCase) || '-') + " ")])], 1), _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Jenis Potongan Kode ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "color": "#333",
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s((_vm$schema6 = _vm.schema) === null || _vm$schema6 === void 0 ? void 0 : _vm$schema6.discountType) + " ")])], 1), _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Nilai Potongan Kode ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(((_vm$schema7 = _vm.schema) === null || _vm$schema7 === void 0 ? void 0 : _vm$schema7.discountType) == 'percent' ? "".concat((_vm$schema8 = _vm.schema) === null || _vm$schema8 === void 0 ? void 0 : _vm$schema8.discount, "%") : _vm.formatCurrency((_vm$schema9 = _vm.schema) === null || _vm$schema9 === void 0 ? void 0 : _vm$schema9.discount)) + " ")])], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "width": "100%",
      "padding": "10px 16px",
      "border-bottom": "1px solid #888",
      "font-size": "20px",
      "color": "primary.400",
      "font-weight": "700",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" Program Terpilih ")]), ((_vm$schema10 = _vm.schema) === null || _vm$schema10 === void 0 ? void 0 : (_vm$schema10$products = _vm$schema10.products) === null || _vm$schema10$products === void 0 ? void 0 : _vm$schema10$products.length) > 0 ? _c('c-box', {
    attrs: {
      "as": "ul",
      "display": "flex",
      "flex-direction": "column",
      "list-style-position": "outside",
      "padding-left": "40px",
      "gap": "4px"
    }
  }, _vm._l((_vm$schema11 = _vm.schema) === null || _vm$schema11 === void 0 ? void 0 : _vm$schema11.products, function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "as": "li",
        "font-size": "16px",
        "font-weight": "400",
        "color": "#111"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")]);
  }), 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "margin-bottom": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "width": "100%",
      "padding": "10px 16px",
      "border-bottom": "1px solid #888",
      "font-size": "20px",
      "color": "primary.400",
      "font-weight": "700",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" Ketentuan Poin ")]), _c('c-grid', {
    attrs: {
      "w": "100%",
      "padding-inline": "16px",
      "template-columns": ['repeat(1, 1fr)'],
      "gap": ['20px'],
      "mx": "auto"
    }
  }, [_c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Minimal Nilai Transaksi ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s((_vm$schema12 = _vm.schema) !== null && _vm$schema12 !== void 0 && _vm$schema12.poinDivider ? _vm.formatCurrency((_vm$schema13 = _vm.schema) === null || _vm$schema13 === void 0 ? void 0 : _vm$schema13.poinDivider) : '-') + " ")])], 1), _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Poin per Nilai Transaksi ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(((_vm$schema14 = _vm.schema) === null || _vm$schema14 === void 0 ? void 0 : _vm$schema14.poinMultiplier) || '-') + " Poin ")])], 1)], 1)], 1), _c('c-box', [_c('c-text', {
    attrs: {
      "width": "100%",
      "padding": "10px 16px",
      "border-bottom": "1px solid #888",
      "font-size": "20px",
      "color": "primary.400",
      "font-weight": "700",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" Bonus Poin ")]), ((_vm$schema15 = _vm.schema) === null || _vm$schema15 === void 0 ? void 0 : (_vm$schema15$referral = _vm$schema15.referralsMilestone) === null || _vm$schema15$referral === void 0 ? void 0 : _vm$schema15$referral.length) > 0 ? _c('c-box', {
    attrs: {
      "overflow": "auto",
      "width": "100%"
    }
  }, [_c('StepperDot', {
    staticClass: "scrollbar",
    attrs: {
      "steps": _vm.referralsMilestoneReformat
    }
  })], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }