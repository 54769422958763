<template>
  <c-box
    width="100%"
  >
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        {
          label: 'Manajemen Referral',
          href: '/admin/referrals',
          isCurrent: false
        },
        {
          label: 'Detail Skema Referral',
          isCurrent: true
        },
      ]"
    />
    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '20px']"
      :min-height="['unset', '74vh']"
    >
      <c-flex
        width="100%"
        justify-content="space-between"
        align-items="center"
        margin-bottom="20px"
      >
        <c-text
          font-size="28px"
          font-weight="700"
          color="#111"
        >
          Skema AjakSehat
        </c-text>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          min-w="114px"
          h="auto"
          py="13px"
          px="18px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          gap="8px"
          @click="() => {
            $router.push({
              name: 'admin.referrals.schema.edit',
              params: {
                id: $route.params.id
              }
            })
          }"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-edit-2.svg')"
            height="20"
            width="20"
            fill="#FFF"
          />
          Edit
        </c-button>
      </c-flex>
      <c-box
        margin-bottom="20px"
      >
        <c-text
          width="100%"
          padding="10px 16px"
          border-bottom="1px solid #888"
          font-size="20px"
          color="primary.400"
          font-weight="700"
          margin-bottom="16px"
        >
          Banner Skema
        </c-text>
        <c-box
          padding-inline="16px"
        >
          <c-image
            v-if="schema?.bannerUrl"
            :src="schema?.bannerUrl"
            width="300px"
            height="auto"
            padding-left=""
            margin-bottom="16px"
            border-radius="8px"
          />
          <c-box>
            <c-text
              font-size="16px"
              font-weight="400"
              color="primary.400"
            >
              Status Skema
            </c-text>
            <c-text
              font-size="18px"
              font-weight="500"
              color="#333"
            >
              {{ getStatus(schema?.status) }}
            </c-text>
          </c-box>
        </c-box>
      </c-box>
      <c-box
        margin-bottom="20px"
      >
        <c-text
          width="100%"
          padding="10px 16px"
          border-bottom="1px solid #888"
          font-size="20px"
          color="primary.400"
          font-weight="700"
          margin-bottom="16px"
        >
          Periode Skema
        </c-text>
        <c-grid
          w="100%"
          :template-columns="['repeat(2, 1fr)']"
          :gap="['20px']"
          mx="auto"
          padding-inline="16px"
        >
          <c-box>
            <c-text
              font-size="16px"
              font-weight="400"
              color="primary.400"
            >
              Mulai Periode Kode
            </c-text>
            <c-text
              font-size="18px"
              font-weight="500"
              color="#333"
            >
              {{ schema?.startAtCase || '-' }}
            </c-text>
          </c-box>
          <c-box>
            <c-text
              font-size="16px"
              font-weight="400"
              color="primary.400"
            >
              Akhir Periode Kode
            </c-text>
            <c-text
              font-size="18px"
              font-weight="500"
              color="#333"
            >
              {{ schema?.endAtCase || '-' }}
            </c-text>
          </c-box>
          <c-box>
            <c-text
              font-size="16px"
              font-weight="400"
              color="primary.400"
            >
              Jenis Potongan Kode
            </c-text>
            <c-text
              font-size="18px"
              font-weight="500"
              color="#333"
              text-transform="capitalize"
            >
              {{ schema?.discountType }}
            </c-text>
          </c-box>
          <c-box>
            <c-text
              font-size="16px"
              font-weight="400"
              color="primary.400"
            >
              Nilai Potongan Kode
            </c-text>
            <c-text
              font-size="18px"
              font-weight="500"
              color="#333"
            >
              {{ schema?.discountType == 'percent' ? `${schema?.discount}%` : formatCurrency(schema?.discount) }}
            </c-text>
          </c-box>
        </c-grid>
      </c-box>
      
      <c-box
        margin-bottom="20px"
      >
        <c-text
          width="100%"
          padding="10px 16px"
          border-bottom="1px solid #888"
          font-size="20px"
          color="primary.400"
          font-weight="700"
          margin-bottom="16px"
        >
          Program Terpilih
        </c-text>
        <c-box
          v-if="schema?.products?.length > 0"
          as="ul"
          display="flex"
          flex-direction="column"
          list-style-position="outside"
          padding-left="40px"
          gap="4px"
        >
          <c-box
            v-for="item in schema?.products"
            :key="item.id"
            as="li"
            font-size="16px"
            font-weight="400"
            color="#111"
          >
            {{ item?.name }}
          </c-box>
        </c-box>
      </c-box>
      <c-box
        margin-bottom="20px"
      >
        <c-text
          width="100%"
          padding="10px 16px"
          border-bottom="1px solid #888"
          font-size="20px"
          color="primary.400"
          font-weight="700"
          margin-bottom="16px"
        >
          Ketentuan Poin
        </c-text>
        
        <c-grid
          w="100%"
          padding-inline="16px"
          :template-columns="['repeat(1, 1fr)']"
          :gap="['20px']"
          mx="auto"
        >
          <c-box>
            <c-text
              font-size="16px"
              font-weight="400"
              color="primary.400"
            >
              Minimal Nilai Transaksi
            </c-text>
            <c-text
              font-size="18px"
              font-weight="500"
              color="#333"
            >
              {{ schema?.poinDivider ? formatCurrency(schema?.poinDivider) : '-' }}
            </c-text>
          </c-box>
          <c-box>
            <c-text
              font-size="16px"
              font-weight="400"
              color="primary.400"
            >
              Poin per Nilai Transaksi
            </c-text>
            <c-text
              font-size="18px"
              font-weight="500"
              color="#333"
            >
              {{ schema?.poinMultiplier || '-' }} Poin
            </c-text>
          </c-box>
        </c-grid>
      </c-box>
      
      <c-box>
        <c-text
          width="100%"
          padding="10px 16px"
          border-bottom="1px solid #888"
          font-size="20px"
          color="primary.400"
          font-weight="700"
          margin-bottom="16px"
        >
          Bonus Poin
        </c-text>
        <c-box
          v-if="schema?.referralsMilestone?.length > 0"
          overflow="auto"
          width="100%"
        >
          <StepperDot
            :steps="referralsMilestoneReformat"
            class="scrollbar"
          />
        </c-box>
        <!-- <c-box
          v-if="schema?.referralsMilestone?.length > 0"
          as="ul"
          display="flex"
          flex-direction="column"
          list-style-position="outside"
          padding-left="40px"
          gap="4px"
        >
          <c-box
            v-for="item in schema?.referralsMilestone"
            :key="item.id"
            as="li"
            font-size="16px"
            font-weight="400"
            color="#111"
          >
            {{ item?.label }}<br>Extra Poin: {{ item?.extraPoin }}<br>Target Poin: {{ item?.totalPoin }}
          </c-box>
        </c-box> -->
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { reqReferral } from '@/requests/dietela-api/referral'
import { formatCurrency } from '@/utils/format-currency'
import StepperDot from '@/components/elements/stepper-dot.vue'

export default {
  name: 'AdminReferralsSchemaDetail',
  components: {
    BreadcrumbPath,
    StepperDot,
  },
  data() {
    return {
      schema: {},
    }
  },
  computed: {
    schemaId() {
      return this.$route.params.id
    },
    referralsMilestoneReformat() {
      return this.schema?.referralsMilestone?.map((item) => ({
        ...item,
        title: item?.totalPoin,
        label: item?.label,
        isCurrentStep: true,
      })) || []
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    formatCurrency,
    async init() {
      try {
        const res = await reqReferral.detailSchema(this.$store.getters.axios, { schemaId: this.schemaId })
        this.schema = res.data?.data
      } catch (err) {
        console.log(err)
      }
    },
    getStatus(status) {
      switch (status) {
        case -1:
          return 'Pending'
        case 0:
          return 'Nonaktif'
        case 1:
          return 'Aktif'
        default:
          return '-'
      }
    },
  },
}
</script>

<style>

</style>